function getLooseFromCookie() {
    return document.cookie.split(';').filter((c) => c.includes('sidebar-loose=true')).length > 0;
}

function setLooseCookie(val) {
    document.cookie = 'sidebar-loose=' + val;
}

export default {
    isLoose: () => {
        return getLooseFromCookie();
    },
    toggleLoose: (l) => {
        let state = getLooseFromCookie();
        state = !state;
        setLooseCookie(state);
    }
}
