require('normalize.css')
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
require('bootstrap/dist/js/bootstrap');
require('datatables.net-bs' );
require('datatables.net-responsive-bs' );
require('metismenu');
require('typeface-noto-sans');
require('typeface-overpass');

require('./main.less');

import ToggleState from './js/toggleState.js';

class ey_colors {
   /**
    * Encapsulates functionality to get EY colors as hex or rgb or rgba
    * strings. Usage:
    *
    * var colors = new ey_colors()
    * alert(colors.as_hex('yellow')) // '#ffe600'
    * alert(colors.as_rgb('yellow')) // 'rgb(255,230,0)'
    * alert(colors.as_rgba('yellow', opacity=0.7)) // 'rgba(255,230,0,0.7)'
    */
    constructor() {
        this.hex_colors = {
            yellow:'#ffe600',
            yellow50: '#fff27f',
            greyDark: '#333333',
            grey: '#646464',
            greyAlt: '#808080',
            grey1: '#999999',
            grey2: '#c0c0c0',
            grey3: '#f0f0f0',
            blue: '#336699',
            red: '#f04c3e',
            blueSpecial: '#00a3ae',
            purpleSpecial: '#91278f',
            greenSpecial: '#2c973e',
            lilacSpecial: '#ac98db',
            blueSpecial50: '#7fd1d6',
            purpleSpecial50: '#c893c7',
            greenSpecial50: '#95cb89',
            lilacSpecial50: '#d8d2e0'
        };
    }
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }
    as_hex(color) {
        return this.hex_colors[color]
    }
    as_rgba(color, opacity=1.0) {
        var rgbcolor = this.hexToRgb(this.hex_colors[color]);
        return 'rgba('+rgbcolor.r+','+rgbcolor.g+','+rgbcolor.b+','+opacity+')'
    }
    as_rgb(color) {
        var rgbcolor = this.hexToRgb(this.hex_colors[color]);
        return 'rgb('+rgbcolor.r+','+rgbcolor.g+','+rgbcolor.b+')'
    }
}

/**
 * Select between "Pin" and "Unpin" text on toggle depending on
 * sidebar state.
 */
function update_pin_text() {
  var toggle_link = $('#sidebar-toggle > span');
  toggle_link.text(ToggleState.isLoose() ? gettext('Pin menu') : gettext('Unpin menu'));
}

// Activate tooltops by default
$(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();

    if ($('#wrapper').hasClass('has-sidebar')) {

	// Only add all the sidebar JS stuff when we have one
        $('#side-menu').metisMenu();
	$('#sidebar-toggle').click((e) => {
	    ToggleState.toggleLoose();
	    $('#wrapper').toggleClass('sidebar-loose', ToggleState.isLoose())
	    update_pin_text()
	});

	$('#mobile-sidebar-toggle').click((e) => {
	    $('#wrapper').toggleClass('sidebar-mobile-out')
	})
    }

    /* This expands the correct list if a submenu entry is open,
     * but is rather ugly! It's mostly stolen from
     * sb-admin2 */
    var element = $('ul.nav a').filter(".active").parent()
    while (element.is('li')) {
        element = element.parent().addClass('in').parent().addClass('active');
        element.find("a.dropdown-collapse").attr('aria-expanded', 'true');
    }
    
});

// Sets language in change_language form from navigation top bar
// and submits the respective POST form
// This is required because we want the languages to be visible as links
// but the language change needs to be submitted via POST form
function change_lang(language_code) {
    $("#change_language_form input[name=language]").val(language_code);
    $("#change_language_form").submit();
}

function setConsentCookie() {
    var formData = new FormData($('#cookie-form')[0]);
    $.ajax({
        url: "/base/cookie-banner/",
        type: 'POST',
        data: formData,
        async: true,
        cache: false,
        contentType: false,
        enctype: 'multipart/form-data',
        processData: false,
    });
    document.getElementById("cookie-banner").style.visibility = "hidden";
};

function banner_behavior(){
    var form = document.getElementById("cookie-form");
    if (form) {
        form.addEventListener('submit', function(evt){
            evt.preventDefault();
            setConsentCookie()
        });
    }
}

if( document.readyState !== 'loading' ) {
    banner_behavior();
}
else{
    document.addEventListener("DOMContentLoaded", banner_behavior());
}

global.change_lang = change_lang;
global.ey_colors = ey_colors;
window.jQuery = $;
window.$ = $;
